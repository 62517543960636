@tailwind base;
@tailwind components;
@tailwind utilities;

.no-scrollbar::-webkit-scrollbar {
  display: none;
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.cover {
  background: url("/public/images/cover/cover.png") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}

.App {
  width: 100vw;
  height: 100vh;
  background: #fff;
  color: #212121;
  font-family: "Open Sans", sans-serif;
  display: grid;
  place-items: center;
}

body {
  margin: 0%;
  padding: 0%;
}


.RTA h1 {
  font-size: 2rem;
}

.RTA h2 {
  font-size: 1.5rem;
}

.RTA h3 {
  font-size: 1.17rem;
}

.RTA li {
  list-style-type: disc;
}

.RTA ol > li {
  list-style: decimal;
}

.RTA a {
  text-decoration: underline;
}